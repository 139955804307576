import API from 'Lib/axios';

import * as actionTypes from 'Store/coordinatorReports/actionTypes';

export const setReportsAction = (reports: Array<{}>) => ({
  type: actionTypes.SET_REPORTS,
  payload: {
    reports,
  },
});

export const setReportAction = (reports: Array<{}>) => ({
  type: actionTypes.SET_REPORT,
  payload: {
    reports,
  },
});

export const clearReportsAction = () => ({
  type: actionTypes.CLEAR_REPORTS,
});

export const clearReportAction = () => ({
  type: actionTypes.CLEAR_REPORT,
});

export const fetchReports = () => {
  return API.get(`/user/bot/charts`);
};

export const fetchReport = () => {
  return API.get('/');
};

export const setReportsReadyAction = () => ({
  type: actionTypes.SET_REPORTS_READY,
});

export const setReportReadyAction = () => ({
  type: actionTypes.SET_REPORT_READY,
});

export const getReports = (init = false) => {
  return (dispatch) => {
    fetchReports().then((res) => {
      const reports = res.data;
      dispatch(setReportsAction(reports));
      if (init) {
        dispatch(setReportsReadyAction());
      }
    });
  };
};

export const getReport = () => {
  return (dispatch) => {
    fetchReport().then((res) => {
      const { reports } = res.data;
      dispatch(setReportAction(reports));
      dispatch(setReportReadyAction());
    });
  };
};
