import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';
import NoResults from 'Components/@Shared/NoResults';
import Loader from 'Components/@Shared/Loader';
import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { clearReportsAction, getReports } from 'Store/employeeReports/actions';
import { Box, Tab, Tabs, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReportsEmployee = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { reports, isReady } = useAppSelector((state) => state.employeeReports);
  const [value, setValue] = React.useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch<any>(getReports(true));

    return () => {
      dispatch(clearReportsAction());
    };
  }, [dispatch]);

  const dialogsData = reports.dialogs.map((dialog) => ({
    x: dialog.x,
    y: dialog.y,
  }));
  const dialogsDataTotal = reports.dialogs.map((dialog) => dialog.y).reduce((a, b) => a + b, 0);
  const durationsDataTotal = reports.durations.map((duration) => duration.y).reduce((a, b) => a + b, 0);

  const durationsData = reports.durations.reverse().map((duration) => ({
    x: duration.x,
    y: duration.y,
  }));

  const xAxisData: any = {
    id: 'barCategories',
    data: dialogsData.map((d) => `${d.x?.slice(0, windowWidth / 180)}. ${d.x?.slice(-3)}`),
    scaleType: 'band',
  };

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>{t('Moje raporty')}</div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Przeprowadzone rozmowy" {...a11yProps(0)} />
                    <Tab label="Czas rozmowy" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    {isReady ? (
                      !!dialogsData.length && (
                        <>
                          <h5>{`Liczba przeprowadzonych rozmów: ${dialogsDataTotal || '-'}`}</h5>
                          <div
                            style={{
                              width: '100%',
                              // maxWidth: '1000px',
                              height: '300px',
                              margin: '0 auto',
                            }}
                          >
                            <BarChart
                              sx={{
                                width: '100%',
                                height: '100%',
                                '& .MuiChartsAxis-tickLabel': {
                                  display: 'absolute !important',
                                },
                              }}
                              colors={['#024a7c']}
                              xAxis={[
                                {
                                  ...xAxisData,
                                  // valueFormatter: (value1: string) => {
                                  //   <span
                                  //     style={{
                                  //       overflow: 'hidden',
                                  //       textOverflow: 'ellipsis',
                                  //     }}
                                  //   >
                                  //     {value1}
                                  //   </span>;
                                  // },
                                },
                              ]}
                              series={[
                                {
                                  data: dialogsData.map((d) => d.y),
                                },
                              ]}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <Loader minHeight="300px" />
                    )}

                    {isReady && dialogsData.length === 0 && <NoResults />}
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    {isReady ? (
                      !!durationsData.length && (
                        <>
                          <h5>{`Liczba czasu rozmów: ${durationsDataTotal || '-'}`}</h5>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              // maxWidth: '800px',
                              height: '300px',
                              margin: '0 auto',
                            }}
                          >
                            <LineChart
                              sx={{ width: '100%', height: '100%' }}
                              xAxis={[{ data: durationsData.map((d) => d.x) }]}
                              series={[
                                {
                                  data: durationsData.map((d) => d.y),
                                },
                              ]}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <Loader minHeight="300px" />
                    )}

                    {isReady && durationsData.length === 0 && <NoResults />}
                  </div>
                </CustomTabPanel>
              </Box>
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default ReportsEmployee;
