import * as ROUTES from 'Constants/routes';

import { MdDashboard, MdArrowForward, MdGroups2, MdOutlineVideoLibrary } from 'react-icons/md';
import { IconType } from 'react-icons/lib';
import { FaUser } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { TbReport, TbReportAnalytics, TbSchema } from 'react-icons/tb';
import { BiBot } from 'react-icons/bi';
import { IoIosSchool } from 'react-icons/io';
import { IoAnalytics, IoBookSharp } from 'react-icons/io5';
import { ImFilm } from 'react-icons/im';
import { GiConversation } from 'react-icons/gi';

type SIDEBAR_PROPS = Array<{
  id: string;
  menuName: string;
  classNames: string;
  level: number;
  isCoordinator?: boolean;
  children: Array<{
    id: string;
    label: string;
    leftIcon?: IconType;
    isImage?: boolean;
    image?: string;
    rightIcon?: IconType;
    url?: string;
    url_newPage?: string;
    submenuName?: string;
    exact?: boolean;
    isCoordinator?: boolean;
    border?: boolean;
  }>;
}>;

const SIDEBAR_LINKS: SIDEBAR_PROPS = [
  {
    id: 'main',
    menuName: 'main',
    level: 0,
    classNames: 'menu-l0',
    children: [
      {
        id: 'main_dashboard',
        label: 'Tablica',
        leftIcon: MdDashboard,
        url: ROUTES.HOME,
        border: true,
        exact: true,
      },

      {
        id: 'main_bot',
        label: 'Bot',
        leftIcon: BiBot,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.BOT,
        border: true,
      },
      {
        id: 'main_scripts',
        label: 'Skrypty rozmów',
        leftIcon: IoIosSchool,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.SCRIPTS,
        border: true,
      },
      {
        id: 'main_conversations',
        label: 'Rozmowy',
        leftIcon: GiConversation,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.CONVERSATIONS,
        border: true,
      },
      {
        id: 'main_elements',
        label: 'Elementy rozmów',
        leftIcon: MdOutlineVideoLibrary,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.ELEMENTS,
        border: true,
      },
      {
        id: 'main_reports',
        label: 'Szkolenia VOD',
        leftIcon: ImFilm,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url_newPage: 'https://akademia.saleup.pl/logowanie.html',
        border: true,
      },
      {
        id: 'main_ebooks',
        label: 'E-booki',
        leftIcon: IoBookSharp,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.EBOOKS,
        border: true,
      },
      {
        id: 'main_training',
        label: 'Raporty',
        leftIcon: IoAnalytics,
        // rightIcon: MdArrowForward,
        // submenuName: 'bot',
        url: ROUTES.REPORTS_EMPLOYEE,
        border: true,
      },
      // {
      //   id: 'main_tickets',
      //   label: 'Wsparcie',
      //   leftIcon: BiSupport,
      //   url: ROUTES.TICKETS,
      //   border: true,
      // },
      {
        id: 'main_coordinator',
        label: 'Koordynator',
        leftIcon: FaUser,
        rightIcon: MdArrowForward,
        submenuName: 'koordynator',
        isCoordinator: true,
      },
    ],
  },
  {
    id: 'koordynator',
    menuName: 'koordynator',
    classNames: 'menu-l1',
    isCoordinator: true,
    level: 1,
    children: [
      {
        id: 'koordynator_users',
        label: 'Użytkownicy',
        leftIcon: BsPeopleFill,
        url: ROUTES.USERS,
        border: true,
      },
      {
        id: 'koordynator_groups',
        label: 'Grupy',
        leftIcon: MdGroups2,
        url: ROUTES.GROUPS,
        border: true,
      },
      {
        id: 'koordynator_reports',
        label: 'Raporty',
        leftIcon: TbReportAnalytics,
        url: ROUTES.REPORTS,
        border: true,
      },
      {
        id: 'koordynator_schemas',
        label: 'Schematy',
        leftIcon: TbSchema,
        url: ROUTES.SCHEMAS,
        border: true,
      },
      {
        id: 'koordynator_analytics',
        label: 'Analityka',
        leftIcon: TbReport,
        url: ROUTES.ANALITICS,
      },
    ],
  },
];

export default SIDEBAR_LINKS;
