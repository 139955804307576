import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cnb } from 'cnbuilder';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { useAppDispatch, useAppSelector } from 'Hooks/redux-hooks';
import { getSchema } from 'Store/schemas/actions';
import Button from 'Components/@Base/Button';
import Cards from 'Containers/Bot/components/Cards/Cards';
import { schemasDataFinance, schemasDataSafety } from 'Containers/Bot/components/schemas';

import { CircularProgress, Grid } from '@mui/material';
import { AiOutlineTrophy } from 'react-icons/ai';

import botStyles from 'Containers/Bot/Bot.module.scss';
import API from 'Lib/axios';
import ButtonWrapper from 'Components/@Shared/ButtonWrapper';

const languageCode = 'pl-PL';
const encoding = 'FLAC';

const Groups = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { schema } = useAppSelector((state) => state.schemas);

  let stream = null;
  let recording = false;
  let encoder = null;
  let input = null;
  let node = null;
  let samplerate = 44100;
  const compression = 5;
  const autoSelectSamplerate = true;
  const flacdata = { bps: 16, channels: 1, compression };

  const [currentResponse, setCurrentResponse] = useState(null);
  const [schemaId, setSchemaId] = useState(0);
  const [isReadySchema, setIsReadySchema] = useState(false);
  const [stepId, setStepId] = useState('0');
  const [stepCount, setStepCount] = useState(0);
  // const [stageState, setStage] = useState('0');
  const [currentSchema, setCurrentSchema] = useState(null);
  const [isRandom, setIsRandom] = useState(false);
  const [isSayMessage, setIsSayMessage] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [score, setScore] = useState<any>('');
  const [testScore, setTestScore] = useState('');
  const [nextStepIds, setNextStepIds] = useState(['1']);
  const [possibleStepsForward, setPossibleStepsForward] = useState([]);
  const [onlyOneOption, setOnlyOneOption] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [currentAudio, setCurrentAudio] = useState('');
  const [currentHint, setCurrentHint] = useState('');

  useEffect(() => {
    if (isReadySchema) {
      dispatch(getSchema(schemaId, true));
    }
    // dispatch(getSchemas(true));
  }, [dispatch, schemaId, isReadySchema]);

  function findObjectByValue(targetValue) {
    if (isRandom) {
      return possibleStepsForward[0];
    }
    if (isSayMessage) {
      return possibleStepsForward[0];
    }
    return possibleStepsForward.find((obj) =>
      obj.values.some((valueObj) => valueObj.text.toLowerCase() === targetValue.toLowerCase()),
    );
  }

  const makeApiRequest = async (data) => {
    try {
      setLoading(true);
      const rawResponse = await API.post(`/user/bot/reply/${schema.id}`, {
        audio: data.audio.content,
        // body: JSON.stringify(data),
        // headers: { 'Content-Type': 'application/json' },
      });
      const response = await rawResponse.data;
      setLoading(false);

      setCurrentResponse(response);
      // if (response && !isRandom && !isSayMessage && !resultObject) {
      //   createToastr(t('Błędne słowa'), t('Błąd'));
      // } else if (response === '') {
      //   createToastr(t('Nie zidentyfikowano odpowiedzi'), t('Błąd'));
      // } else if (resultObject) {
      //   if (isSayMessage) {
      //     setIsSayMessage(false);
      //     nextStep();
      //   } else if (isRandom) {
      //     const randomIndex = Math.floor(Math.random() * resultObject.nextSteps.length);
      //     const randomElement = resultObject.nextSteps[randomIndex];
      //     nextStep([`${randomElement}`]);
      //     setIsRandom(false);
      //   } else nextStep(resultObject.nextSteps);
      // }
      // else if (isRandom) {
      //   setCurrentResponse(response);
      //   nextStep(resultObject.nextSteps);
      // }
    } catch (error) {
      null;
    }
  };

  const startRecording = function () {
    encoder = new Worker('./encoder/encoder.js');
    encoder.onmessage = function (e) {
      if (e.data.cmd === 'end') {
        const reader: any = new window.FileReader();
        if (!e.data.buf) return;
        reader.readAsDataURL(e?.data?.buf);
        reader.onloadend = async function () {
          if (reader.result.length < 9) return;
          const audioData = reader.result.replace(/^data:audio\/flac;base64,/, '');
          if (audioData.length < 6) return;
          const data = {
            config: { encoding, sampleRateHertz: samplerate, languageCode },
            audio: { content: audioData },
          };
          makeApiRequest(data);
        };
        encoder.terminate();
        encoder = null;
      }
    };
    // @ts-ignore
    navigator.getUserMedia =
      // @ts-ignore
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    // @ts-ignore
    navigator.getUserMedia({ video: false, audio: true }, gotUserMedia, userMediaFailed);
  };

  const userMediaFailed = function (code) {
    alert(`grabbing microphone failed: ${code}`);
  };

  const updateUI = (variant) => {
    if (variant === 'start') {
      recording = true;
    } else if (variant === 'stop') {
      recording = false;
    }
  };

  const gotUserMedia = function (localMediaStream) {
    updateUI('start');
    // setStream(localMediaStream);
    stream = localMediaStream;
    let audio_context;
    // if (typeof webkitAudioContext !== 'undefined') {
    //   audio_context = new webkitAudioContext();
    // } else
    if (typeof AudioContext !== 'undefined') {
      audio_context = new AudioContext();
    } else {
      alert('Nie można rozpocząć nagrywania dźwięku. Twoja przeglądarka nie obsługuje dźwięku internetowego!');
      return;
    }
    // audio_context = audio_context;
    input = audio_context.createMediaStreamSource(stream);
    if (input.context.createJavaScriptNode) node = input.context.createJavaScriptNode(4096, 1, 1);
    else if (input.context.createScriptProcessor) node = input.context.createScriptProcessor(4096, 1, 1);
    // else console.error('Could not create audio node for JavaScript based Audio Processing.');

    const { sampleRate } = audio_context;
    if (autoSelectSamplerate) {
      samplerate = sampleRate;
    }

    encoder?.postMessage({
      cmd: 'init',
      config: {
        samplerate,
        bps: flacdata.bps,
        channels: flacdata.channels,
        compression,
      },
    });
    node.onaudioprocess = function (e) {
      if (!recording) return;
      const channelLeft = e.inputBuffer.getChannelData(0);
      encoder?.postMessage({ cmd: 'encode', buf: channelLeft });
    };

    input.connect(node);
    node.connect(audio_context.destination);
  };

  const stopRecording = function () {
    // if (!recording) return;
    const tracks = stream?.getAudioTracks() ? stream.getAudioTracks() : [];
    for (let i = tracks.length - 1; i >= 0; --i) {
      tracks[i].stop();
    }
    encoder?.postMessage({ cmd: 'finish' });

    input?.disconnect();
    node?.disconnect();
    input = null;
    node = null;
  };

  const nextStep = (nextids = undefined) => {
    setStepCount(stepCount + 1);
    const foundObjects = schema?.tree?.filter((obj) =>
      nextids ? nextids.includes(obj.id) : nextStepIds.includes(obj.id),
    );
    const foundObject = foundObjects?.[0];
    const possibleObjects = schema?.tree?.filter((obj) => foundObject?.nextSteps?.includes(obj.id));

    possibleObjects?.length === 1 && setOnlyOneOption(true);
    possibleObjects?.length > 1 && setOnlyOneOption(false);

    setPossibleStepsForward(possibleObjects);
    setStepId('2');
    setStepId(nextids?.[0] || nextStepIds?.[0]);
    setNextStepIds(foundObject?.nextSteps);
    if (onlyOneOption) {
      switch (foundObject?.kind) {
        case 'wait_for_input':
          if (possibleObjects[0].kind === 'random') {
            setIsRandom(true);
          } else if (possibleObjects[0].kind === 'say_message') {
            setNextStepIds(foundObject?.nextSteps);
            setIsSayMessage(true);
          } else if (possibleObjects[0].kind === 'end') {
            setIsSayMessage(true);
          }
          break;
        case 'say_message':
          setCurrentAudio(foundObject?.values[0].url);
          break;
        case 'hint':
          setCurrentHint(foundObject?.values?.[0]?.text);
          nextStep(foundObject?.nextSteps);
          break;
        case 'random':
          break;
        case 'end':
          setIsEnd(true);
          setTestScore(foundObject?.values?.[0]?.text);
          // handleEndConversation(foundObject?.values?.[0]?.text, foundObject?.step);
          break;

        default:
          break;
      }
    } else {
      // nextStep();
      setCurrentAudio(foundObject?.values[0].url);
    }
  };
  let countTime = 0;
  const handleStartTimer = (time) => {
    countTime = time;
  };

  const handleAudioEnded = () => {
    setCurrentAudio('');
    nextStep();
  };

  const handleMouseDown = () => {
    const element = document.getElementById('recordButton');
    element.className = botStyles.pulse_button;
    startRecording();
  };

  const handleMouseUp = () => {
    const element = document.getElementById('recordButton');
    element.className = botStyles.pulse_button_end;
    stopRecording();
  };

  // const handleEndConversation = (score, step) => {
  //   API.post('/reports', {
  //     schema_id: schemaId,
  //     success: score === '100',
  //     steps_completed: stepCount,
  //     time: countTime,
  //     stage: step,
  //   })
  //     .then((res) => {
  //       console.log(res, 'res');
  //     })
  //     .catch((err) => {
  //       console.log(err, 'err');
  //     });
  // };
  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>
            {isReadySchema ? t('Bot') : t('Schematy')}
            <div className={pageHeaderStyles.PageHeader__actions} />
          </div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <div style={{ position: 'relative' }}>
              {stepId !== '0' && !isEnd ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 100,
                  }}
                >
                  <ButtonWrapper
                    alignment="center"
                    label="Zakończ i oceń rozmowę"
                    type="button"
                    buttonType="secondary"
                    isSubmitting={loadingButton}
                    isDisabled={loadingButton}
                    onClick={() => {
                      setLoadingButton(true);
                      API.post(`/user/bot/close/${schema.id}`).then((res) => {
                        setScore(res.data);
                        setIsEnd(true);
                        setLoadingButton(false);
                      });
                    }}
                  />
                </div>
              ) : null}
              {isReadySchema ? null : (
                <Grid container spacing={2}>
                  <div className="d-flex flex-wrap justify-content-start w-100">
                    {schemasDataFinance?.map((item) => (
                      <Grid key={item.id} item xs={8} lg={5} xl={4}>
                        <Cards
                          item={item}
                          setIsReadySchema={setIsReadySchema}
                          setSchemaId={setSchemaId}
                          setCurrentSchema={setCurrentSchema}
                        />
                      </Grid>
                    ))}
                  </div>

                  <div className="d-flex flex-wrap justify-content-start w-100">
                    {schemasDataSafety?.map((item) => (
                      <Grid key={item.id} item xs={8} lg={5} xl={4}>
                        <Cards
                          item={item}
                          setIsReadySchema={setIsReadySchema}
                          setSchemaId={setSchemaId}
                          setCurrentSchema={setCurrentSchema}
                        />
                      </Grid>
                    ))}
                  </div>
                </Grid>
              )}
              {isReadySchema && (
                <div className={botStyles.Iphone_container}>
                  {/* <div className={botStyles.Iphone__content_container}> */}
                  {stepId === '0' ? (
                    <div className="d-flex flex-column align-items-center h-100">
                      <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>{currentSchema?.title}</h3>
                      <h5 style={{ textAlign: 'center', marginBottom: 20 }}>
                        {t('Poziom trudności')}: {currentSchema?.level}
                      </h5>
                      <h5 style={{ textAlign: 'center' }}>
                        {t(
                          'Za chwilę rozpoczniesz rozmowę z botem. Pamiętaj, aby rozpocząć rozmowę od przywitania się.',
                        )}
                      </h5>
                      <h5 style={{ textAlign: 'center', marginBottom: 20 }}>{t('Powodzenia')}</h5>
                      <Button
                        className="w-25"
                        label={t('Rozpocznij')}
                        type="button"
                        buttonType="secondary"
                        onClick={() => {
                          nextStep();
                        }}
                      />
                    </div>
                  ) : isEnd ? (
                    <div className="d-flex flex-column align-items-center h-100">
                      <AiOutlineTrophy size={100} color="gold" />
                      <h3 style={{ fontWeight: 'bold', marginBottom: 10 }}>{t('Gratulacje')}</h3>
                      <h5 style={{ textAlign: 'center', marginBottom: 10 }}>
                        {t('Twój wynik to')} <strong>{score[0]?.score}</strong>
                      </h5>
                      <h5 style={{ textAlign: 'center', marginBottom: 20 }}>
                        Opis: <strong>{score[0]?.description}</strong>
                      </h5>
                      <Button
                        className="w-25"
                        label={t('Zakończ')}
                        type="button"
                        buttonType="secondary"
                        onClick={() => {
                          setStepId('0');
                          setIsEnd(false);
                          setIsReadySchema(false);
                          setSchemaId(0);
                          setCurrentResponse(null);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className={botStyles.Iphone_content}>
                        {/* <Timer handleStartTimer={handleStartTimer} /> */}

                        <>
                          <span>{t('Naciśnij i przytrzymaj, aby nagrać')}</span>
                          {loading ? (
                            <CircularProgress
                              sx={{
                                width: '50px !important',
                                height: '50px !important',
                                marginTop: '20px',
                                marginBottom: '20px',
                              }}
                            />
                          ) : (
                            <button
                              id="recordButton"
                              className={cnb(botStyles.pulse_button_end)}
                              onMouseDown={handleMouseDown}
                              onMouseUp={handleMouseUp}
                              type="button"
                            >
                              &nbsp;
                            </button>
                          )}
                          {!!currentResponse && (
                            <div className="text-center offset3 span6 form-group">
                              {t('Twoja wypowiedź')}:
                              <div id="loading" className="hidden">
                                <b>{currentResponse?.request_text ?? `${t('Ładowanie')}...`}</b>
                              </div>
                              <div id="translation" />
                            </div>
                          )}
                          {!!currentResponse && (
                            <div>
                              <audio
                                style={{ width: '350px' }}
                                id="audio"
                                controls
                                // className="audioBtn"
                                src={`data:audio/mpeg;base64,${currentResponse?.response_audio}`}
                                onEnded={handleAudioEnded}
                                autoPlay
                              >
                                <track kind="captions" src="path/to/captions.vtt" srcLang="en" label="English" />
                              </audio>
                            </div>
                          )}
                          <hr style={{ border: '2px solid grey', width: '150px', borderRadius: '0.25rem' }} />
                          {!!currentResponse && (
                            <>
                              <div style={{ textAlign: 'center' }}>
                                <strong style={{ fontWeight: 'bold' }}>{t('Klient')}: </strong>
                                <span>{currentResponse?.response_text}</span>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </>
                  )}
                </div>
                // </div>
              )}
            </div>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Groups;
