import { useTranslation } from 'react-i18next';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';

import { Box, Grid, List, ListItem, Typography } from '@mui/material';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { FaPiggyBank } from 'react-icons/fa';
import { BsShieldFillCheck } from 'react-icons/bs';

const Analytics = () => {
  const { t } = useTranslation();
  const arrayFinanse = [
    {
      title: 'Skrypt rozmowy Finanse 1.0',
      link: 'https://drive.google.com/file/d/1wLAWwFf-B8aZII-PQPrK602clufgbZvi/view',
    },
    {
      title: 'Skrypt rozmowy Finanse 1.1',
      link: 'https://drive.google.com/file/d/1XX0FpVDL6cbuWYqL9fF9eXczc1eIhX_h/view',
    },
    {
      title: 'Skrypt rozmowy Finanse 1.2',
      link: 'https://drive.google.com/file/d/1U6Dh7ei3HKIH6ZMGhp-4qSZ_6qtH07Wz/view',
    },
    {
      title: 'Skrypt rozmowy Finanse 1.3',
      link: 'https://drive.google.com/file/d/16eTNU3paQDodDUHpKh2pUGOP83EDOO43/view',
    },
    {
      title: 'Skrypt rozmowy Finanse 1.4',
      link: 'https://drive.google.com/file/d/1A_uiCkbX-sPzppu_i44D6KkK2K1zs1fx/view',
    },
  ];

  const arrayUbezpieczenie = [
    {
      title: 'Skrypt rozmowy Ubezpieczenie 1.0',
      link: 'https://drive.google.com/file/d/11RiMfAmos3YIRqzWHjHEBbuQcfws_SoR/view',
    },
    {
      title: 'Skrypt rozmowy Ubezpieczenie 1.1',
      link: 'https://drive.google.com/file/d/1SqVk5-irPIUj76nVHty1TjaiG9V-UP9G/view',
    },
    {
      title: 'Skrypt rozmowy Ubezpieczenie 1.2',
      link: 'https://drive.google.com/file/d/15UIGqbZT6GMTSMZSN54jZERZQDa61JWM/view',
    },
    {
      title: 'Skrypt rozmowy Ubezpieczenie 1.3',
      link: 'https://drive.google.com/file/d/1Avayet4MTX658QxmbyprropfzY-rPU88/view',
    },
    {
      title: 'Skrypt rozmowy Ubezpieczenie 1.4',
      link: 'https://drive.google.com/file/d/1aXnTZCEkc4xzsBrKNJSvrfzCdNg-Tj8k/view',
    },
  ];
  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>{t('Skrypty rozmów')}</div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <Box sx={{ width: '100%' }}>
                <Grid item xs={12} md={6}>
                  <Typography sx={{ mt: 4, mb: 2, ml: 3, mr: 3 }} variant="h6" component="div">
                    Skrypt rozmowy sprzedażowej to, najprościej mówiąc, spisany plan rozmowy, jaką zamierza się wykonać.
                    Składa się z listy pytań oraz schematów potencjalnych odpowiedzi. Przydaje się on najczęściej
                    podczas kontaktu telefonicznego, ale bywa także pomocny podczas bezpośrednich spotkań z klientami.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <List sx={{ ml: 3, mr: 3 }}>
                      {arrayFinanse.map((item) => (
                        <ListItem
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <FaPiggyBank fontSize={25} color="blue" />
                          &nbsp;&nbsp;
                          <a
                            style={{ color: '#024a7c', lineHeight: '20px' }}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                        </ListItem>
                      ))}
                    </List>
                    <List sx={{ ml: 3, mr: 3 }}>
                      {arrayUbezpieczenie.map((item) => (
                        <ListItem
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <BsShieldFillCheck fontSize={20} color="blue" />
                          &nbsp;&nbsp;
                          <a
                            style={{ color: '#024a7c', lineHeight: '20px' }}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Box>
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Analytics;
